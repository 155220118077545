<template>
  <el-table-column
    align="left"
  >
    <template slot="header">
      <div>Парт - детализация цели в трёх уровнях.</div>
      <div>Заполните описание вручную или оставьте значения по умолчанию.</div>
    </template>

    <el-table-column
      v-for="partIndex in 3"
      :key="partIndex"
      :label="partLabels[partIndex]"
      width="187"
    >
      <template v-slot="scope">
        <el-input
          v-model="scope.row.scale.current_scale_part['part_'+partIndex]"
          :disabled="!isFieldAvailableForEdit(scope.row, 'scale.current_part_id')"
          size="mini"
          @change="changed(scope, 'part_'+partIndex, $event)"
        ></el-input>
      </template>
    </el-table-column>

  </el-table-column>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import requestSender from "@/api/base/requestSender";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import ElementScalePartSingleTableColumn
  from "@/componentsCompany/cherkizovo/tableColumnTemplates/card/parts/ElementScalePartSingleTableColumn.vue";

export default {
  name: 'element-scale-part-table-column',
  components: {ElementScalePartSingleTableColumn, ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {
      partLabels: {
        1: 'Уровень 1 (канал, направление)',
        2: 'Уровень 2 (площадка, регион)',
        3: 'Уровень 3 (сегмент, продукт)'
      }
    }
  },
  mounted() {
  },
  methods: {
    changed(scope, name, value) {
      requestSender('post', 'scale/update-single-part-for-card-element', {
        element_id: scope.row.id,
        name: name,
        value: value,
      })
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Элемент успешно сохранен'
          });
        })
        .finally(() => {
          this.$emit('elements-changed');
        })
    }
  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

</style>
